import React from "react"
import { Card, TextStyle } from "@shopify/polaris"

export const DraftOrderShippingCard = ({ order }) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Card.Section title="Shipping address">
          {order?.shippingAddress && order.shippingAddress.formatted ? (
            order.shippingAddress.formatted.map((line, i) => <p key={`${i}_${line}`}>{line}</p>)
          ) : (
            <TextStyle variation="subdued">{`No shipping address`}</TextStyle>
          )}
        </Card.Section>
      </Card>
    </div>
  )
}
