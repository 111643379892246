import React from "react"
import { Card, TextContainer, TextStyle, Button, Stack, ResourceList } from "@shopify/polaris"

export const OrderDetailsCard = ({ onOpenPickerModal, order }) => {
  const actions = order
    ? [
        {
          content: "View full details",
        },
      ]
    : []

  const renderOrderPicker = () => {
    return (
      <Card.Section>
        <TextContainer>
          <TextStyle>{`Please select a draft order to create new plan`}</TextStyle>
        </TextContainer>
        <br />
        <Button onClick={() => onOpenPickerModal()}>{`Browse draft orders`}</Button>
        <br />
      </Card.Section>
    )
  }

  const renderOrderDetials = () => {
    return (
      <Card.Section>
        <ResourceList
          resourceName={{ singular: "sale", plural: "sales" }}
          items={[
            {
              sales: "Discount",
              amount: order.appliedDiscount?.value ? `- $${order.appliedDiscount.value.toFixed(2)}` : "- $0.00",
            },
            {
              sales: "Subtotal",
              amount: order.subtotalPrice ? `$${order.subtotalPrice}` : "$0.00",
            },
            {
              sales: "Shipping",
              amount: order.totalShippingPrice ? `$${order.totalShippingPrice}` : "$0.00",
            },
            {
              sales: "Taxes",
              amount: order.totalTax ? `$${order.totalTax}` : "$0.00",
            },
            {
              sales: <TextStyle variation="strong">{`Total`}</TextStyle>,
              amount: <TextStyle variation="strong">{order.totalPrice ? `$${order.totalPrice}` : "$0.00"}</TextStyle>,
            },
          ]}
          renderItem={item => {
            const { sales, amount, url } = item
            return (
              <ResourceList.Item url={url} accessibilityLabel={`View Sales for ${sales}`}>
                <Stack>
                  <Stack.Item fill>{sales}</Stack.Item>
                  <Stack.Item>{amount}</Stack.Item>
                </Stack>
              </ResourceList.Item>
            )
          }}
        />
      </Card.Section>
    )
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Card.Header actions={actions} title="Order details" />
        {order ? renderOrderDetials() : renderOrderPicker()}
      </Card>
    </div>
  )
}
