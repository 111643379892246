import React, { useState, useCallback } from "react"
import { Card, FormLayout, ChoiceList, TextField } from "@shopify/polaris"

export const OrderAttributeCard = ({ order, onUpdateAttributes, isUpdating }) => {
  const [deposit, setDeposit] = useState(null)
  const [error, setError] = useState(false)
  const [availableTerms, setAvailableTerms] = useState(["none"])

  const handleAvilableTermChange = useCallback(value => {
    setAvailableTerms(value)
  }, [])
  const handleDepositChange = value => {
    if (isNaN(value)) {
      return false
    }

    setDeposit(value)
    if (Number(value) >= Number(order.totalPrice)) {
      setError(`Must less than ${order.totalPrice}`)
    } else {
      setError(false)
    }
  }

  const termOptions = [
    { label: "None", value: "none" },
    { label: "2 months", value: "payment-plan:2" },
    { label: "4 months", value: "payment-plan:4" },
    { label: "6 months", value: "payment-plan:6" },
  ]

  const handleSaveChange = () => {
    if (error) {
      return false
    }

    const customAttributes = []
    if (deposit !== "" && Number(deposit) > 0) {
      customAttributes.push({
        key: "_deposit",
        value: deposit,
      })
    }

    if (availableTerms[0] !== "none") {
      customAttributes.push({
        key: "_initial_select",
        value: availableTerms[0],
      })
    }

    onUpdateAttributes(customAttributes)
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <Card
        title="Set initial attributes"
        primaryFooterAction={{
          content: "Save",
          onAction: handleSaveChange,
          loading: isUpdating,
        }}
      >
        <Card.Section>
          <FormLayout>
            <TextField label="Set initial deposit" type="text" value={deposit || 0} onChange={handleDepositChange} error={error} autoComplete="off" />
          </FormLayout>
          <br />
          <ChoiceList title="Select payment terms:" choices={termOptions} selected={availableTerms} onChange={handleAvilableTermChange} />
        </Card.Section>
      </Card>
    </div>
  )
}
