import React from "react"
import { Layout } from "@shopify/polaris"

import { OrderBanner } from "./OrderBanner"
import { OrderDetailsCard } from "./OrderDetailsCard"
import { OrderLineItemSection } from "./OrderLineItemSection"
import { OrderAttributeCard } from "./OrderAttributeCard"
import { OrderConfirmationCard } from "./OrderConfirmationCard"
import { DraftOrderCustomerCard } from "./DraftOrderCustomerCard"
import { DraftOrderShippingCard } from "./DraftOrderShippingCard"

export const OrderForm = ({
  order,
  redirect,
  onOpenPickerModal,
  isUpdating,
  onUpdateLineItems,
  onUpdateAttributes,
  hasConverted,
  isSending,
  onSendLink,
  sendStatus,
}) => {
  return (
    <>
      <OrderBanner redirect={redirect} order={order} onOpenPickerModal={onOpenPickerModal} />
      <br />
      <Layout>
        <Layout.Section>
          <OrderDetailsCard redirect={redirect} order={order} onOpenPickerModal={onOpenPickerModal} />
          {order ? <OrderLineItemSection order={order} isUpdating={isUpdating} onUpdateLineItems={onUpdateLineItems} /> : null}
          {order ? <OrderAttributeCard order={order} isUpdating={isUpdating} onUpdateAttributes={onUpdateAttributes} /> : null}
          {order && hasConverted ? (
            <OrderConfirmationCard order={order} isSending={isSending} onSendLink={onSendLink} sendStatus={sendStatus} />
          ) : null}
        </Layout.Section>
        <Layout.Section secondary>
          <DraftOrderCustomerCard order={order} redirect={redirect} />
          {order ? <DraftOrderShippingCard order={order} redirect={redirect} /> : null}
        </Layout.Section>
      </Layout>
    </>
  )
}
