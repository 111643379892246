import React, { useState, useEffect, useCallback } from "react"
import { Card, Banner, TextField, ChoiceList } from "@shopify/polaris"

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const OrderConfirmationCard = ({ order, isSending, onSendLink, sendStatus }) => {
  const [selected, setSelected] = useState([order.customer?.email])
  const [textFieldValue, setTextFieldValue] = useState("")
  const [error, setError] = useState(false)

  const handleChoiceListChange = useCallback(value => setSelected(value), [])

  const handleTextFieldChange = useCallback(value => setTextFieldValue(value), [])

  useEffect(() => {
    setError(false)
  }, [selected])

  const onClickSendLink = () => {
    const isOther = selected.includes("other")
    const email = isOther ? textFieldValue : selected[0]

    if (isOther && !textFieldValue.match(emailRegex)) {
      setError("Email address is invalid.")
      return false
    } else {
      setError(false)
    }

    onSendLink(email)
  }

  const renderChildren = useCallback(
    () => <TextField label="Other email" labelHidden onChange={handleTextFieldChange} value={textFieldValue} error={error} autoComplete="off" />,
    [handleTextFieldChange, textFieldValue, error]
  )

  const statusMessage =
    sendStatus === "success"
      ? `The invoice has been sent successfully.`
      : sendStatus === "critical"
      ? `Failed. Please check the email addres and try it later.`
      : ""

  return (
    <div style={{ marginBottom: 20 }}>
      <Card
        title="Send the draft order link"
        primaryFooterAction={{
          content: "Send",
          onAction: onClickSendLink,
          loading: isSending,
        }}
      >
        <Card.Section>
          <ChoiceList
            title="Select an email address:"
            choices={[
              { label: `${order.customer?.email}`, value: order.customer?.email },
              {
                label: "Other email",
                value: "other",
                renderChildren,
              },
            ]}
            selected={selected}
            onChange={handleChoiceListChange}
          />
          <br />
          {sendStatus ? <Banner title={statusMessage} status={sendStatus} /> : null}
        </Card.Section>
      </Card>
    </div>
  )
}
