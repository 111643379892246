import React from "react"
import { Redirect } from "@shopify/app-bridge/actions"

import { reverseShopifyAdminId } from "../helpers/utils"

import { Card, TextStyle } from "@shopify/polaris"

export const CustomerCard = ({ id, displayName, email, redirect }) => {
  const handleClickView = () => {
    redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
      section: {
        name: Redirect.ResourceType.Customer,
        resource: {
          id: `${reverseShopifyAdminId(id)}`,
        },
      },
      newContext: true,
    })
  }

  const actions = id ? [{ content: "View", onAction: handleClickView }] : []

  return (
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Card.Section title="Customer" actions={actions}>
          {displayName ? <p>{displayName}</p> : <TextStyle variation="subdued">{`No customer`}</TextStyle>}
        </Card.Section>
        {email && (
          <Card.Section title="Contact Information">
            <p>{email}</p>
          </Card.Section>
        )}
      </Card>
    </div>
  )
}
